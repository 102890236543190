import {Inject, Injectable} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";
import {environment} from "../../environments/environment";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private _document: Document, private transloco: TranslocoService) { }

  updateTitle(title: string){
    let _title = title;
    if(!title) {
      _title ='Карта Надзвичайних Ситуацій';
    }
    else if(title.length > 0) {
      _title +=' - Карта Надзвичайних Ситуацій';
    } else {
      _title +='Карта Надзвичайних Ситуацій';
    }
    //this.title.setTitle(_title);
  }
  updateDescription(description: string){
   // this.updateMetaTags([{name: 'description', content: this._trimStrings(description)}]);
  }
  updateOgMetaTags(OG: any){
    return;
    if(OG.update) {
      let _og_meta: MetaDefinition[] = Object.keys(OG).reduce((meta_definition, property) => {
        if (property !== "update") {
          if (property === "description") {
            meta_definition.push({ property: 'og:'+property, content: this._trimStrings(OG[property]) });
          }
          else {
            meta_definition.push({ property: 'og:'+property, content: OG[property] });
          }
        }
        return meta_definition;
      }, []);
      this.updateMetaTags(_og_meta);
    }

  }
  updateMetaTags(metaTags: MetaDefinition[]){
    metaTags.forEach(m=> this.meta.updateTag(m));
  }


  updateSchemaBreadcrumbs(_crumbs: any): void {
    return;
    let _schema_script = this._document.getElementById('bh-breadcrumb-schema');
    if (!_schema_script) {
      return;
    }
    if (_crumbs.breadcrumbs.length == 0) {
      _schema_script.innerHTML = '';
      return;
    }
    let _breadcrumbSchema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumb",
      "itemListElement": _crumbs.breadcrumbs.map((item, index) => {
        if (!item.translate) {
          return;
        }
        return {
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@id": environment.baseUrl + item.link+'/',
            "url": environment.baseUrl + item.link+'/',
            "name": item.translate
          }
        };
      })
    };

    _schema_script.innerHTML = JSON.stringify(_breadcrumbSchema);
  }
  updateSchemaOrganization() : void {
    return;
    let _organizationSchema =   {
      "@context": "https://schema.org",
      "@type": "Organization",
      "@id": "https://sh.alarmmap.online/",
      "name": "AlarmMap Online",
      "url": "https://sh.alarmmap.online/",
      "logo": "https://sh.alarmmap.online/assets/images/logo_icon.svg",
      "foundingDate": "2022",
      "alternateName": [
        "Alarm Map Online",
        "Карта Надзвичайних Ситуацій",
        "AlarmMap"
      ],
      "description": "",
      "location": {
        "@type": "VirtualLocation",
        "url": "https://sh.alarmmap.online/"
      },
      "sameAs": [
        "https://www.facebook.com/alarmmap.online",
        "https://t.me/alarmmap.online"
      ],
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+3 (098) 000-0000",
          "contactType": "Support",
          "email": "info@alarmmap.online",
          "areaServed": "UK",
          "availableLanguage": "uk"
        }
      ]
    };
    let _schema_script = this._document.getElementById('bh-org-schema');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_organizationSchema);
  }
  updateSchemaWebsite() : void {
    let _websiteSchema =   {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "AlarmMap Online",
      "alternateName": [
        "Alarm Map Online",
        "Карта Надзвичайних Ситуацій",
        "AlarmMap"
      ],
      "url": "https://sh.alarmmap.online/",
      "potentialAction": {
        "@type": "SearchAction",
        "name": "Search",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://sh.alarmmap.online/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    };
    let _schema_script = this._document.getElementById('bh-website-schema');
    if(!_schema_script) { return;}
    _schema_script.innerHTML = JSON.stringify(_websiteSchema);
  }


  updateCanonicalURL(_crumb_url: string, _route_data: any): void {
    return;
    this.clearCanonicalURL();
    let _canonicalUrl = environment.baseUrl+((_crumb_url == '/')? '/' : _crumb_url);
    if(_route_data && _route_data.not_found) {
      _canonicalUrl = environment.baseUrl;
    }

    let _canonicalTag: HTMLLinkElement = this._document.createElement('link');
    _canonicalTag.setAttribute('rel', 'canonical');
    this._document.head.appendChild(_canonicalTag);
    _canonicalTag.setAttribute('href', _canonicalUrl);
    this.updateOgMetaTags({
      "update": true,
      "url": _canonicalUrl
    })
  }
  clearCanonicalURL() {
    const els = this._document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaOrganization(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-org-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaWebsite(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-website-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaCourse(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-course-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
  clearSchemaBreadcrumb(): void {
    const els = this._document.querySelectorAll('script[id=\'bh-breadcrumb-schema\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }




  updateNoIndexUrl(_route_data: any): void {
    this.clearNoIndexUrl();
    if(_route_data && _route_data.noindex) {
      this.updateMetaTags([{name: 'robots', content: "noindex, nofollow"}]);
    }
  }
  clearNoIndexUrl() {
    const els = this._document.querySelectorAll('meta[name=\'robots\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }



  _trimStrings(text: string) {
    if(!text) {
      return '';
    }
    const maxLength = 160;
    const withoutHtmlTags = text.replace(/<\/?[^>]+(>|$)/g, '');
    const trimmedText = withoutHtmlTags.trim();

    if (trimmedText.length <= maxLength) {
      return trimmedText;
    }

    const words = trimmedText.split(' ');
    let truncatedText = '';
    let currentLength = 0;

    for (const word of words) {
      if (currentLength + word.length + 1 <= maxLength) {
        truncatedText += word + ' ';
        currentLength += word.length + 1;
      } else {
        break;
      }
    }

    return truncatedText.trim();
  }

  initSchema(id?: string) {
    return;
    const schemaTypes = [
      { id: "bh-breadcrumb-schema", type: "application/ld+json" },
      { id: "bh-org-schema", type: "application/ld+json" },
      { id: "bh-website-schema", type: "application/ld+json" }
    ];

    const bodyElement = this._document.getElementsByTagName('body')[0];

    if(id) {
      let _schema_script = this._document.createElement('script');
      _schema_script.type = "application/ld+json";
      _schema_script.id = id;
      const _schema_script_element = this._document.getElementById(id);
      if(!_schema_script_element) {
        bodyElement.appendChild(_schema_script);
      }
      return;
    }


    schemaTypes.forEach(schemaType => {
      let _schema_script = this._document.createElement('script');
      _schema_script.type = schemaType.type;
      _schema_script.id = schemaType.id;
      const _schema_script_element = this._document.getElementById(schemaType.id);
      if(!_schema_script_element) {
        bodyElement.appendChild(_schema_script);
      }
    });
  }

}
