import {ExtraOptions, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchPublicPageGuard} from "./_guards/public-page.guard";
import {MatchSirensCategoryGuard} from "./_guards/sirens-category.guard";
import {MatchRegionChildGuard} from "./_guards/region-child.guard";
import {MatchRegionSignalsGuard} from "./_guards/region-signals.guard";


const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30]
};

const routWithComingSoon = [
  {
    path: '',
    children: [
      {
        path: 'contacts',
        loadChildren: () => import('./_ui/pages/public/bh-page-contacts/bh-page-contacts.module').then((mod) => mod.BhPageContactsModule),
      },
      {
        path: 'terms',
        loadChildren: () => import('./_ui/pages/public/bh-page-terms/bh-page-terms.module').then((mod) => mod.BhPageTermsModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./_ui/pages/public/bh-page-privacy-policy/bh-page-privacy-policy.module').then((mod) => mod.BhPagePrivacyPolicyModule),
      },

      {
        path: 'scheduler',
        loadChildren: () => import('./_ui/pages/private/bh-page-scheduler/bh-page-scheduler.module').then((mod) => mod.BhPageSchedulerModule),
      },

      {
        path: '',
        children: [
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          }
        ]
      },
      {
        path: '**',
        redirectTo: '/'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], routerOptions),
    RouterModule.forChild(routWithComingSoon),
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
