import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SignalItemModel, SignalsModel, SignalTypeModel} from "../_models/signals.model";
import {SignalsService} from "../_services/signals.service";
import {
  InitSSESignals,
  InitSSETimelines,
  LoadSignalsFromApi,
  LoadSignalsFromJson,
  UpdateActualSignals,
  UpdateActualTimelines
} from "../_actions/signals.actions";


import {AtuState} from "../../atu/_state/atu.state";
import {AtuItemModel} from "../../atu/_models/atu.model";
import {LoadAtuActualGeo} from "../../atu/_actions/atu.actions";

export const _SignalsDefault: SignalsModel = {
  actual: [],
  types: [
    {
      name:"air",
      slug:"air"
    },
    {
      name:"chemical",
      slug:"chemical"
    },
    {
      name:"radiation",
      slug:"radiation"
    },
    {
      name:"artillery",
      slug:"artillery"
    },
    {
      name:"fight",
      slug:"fight"
    },
    {
      name:"fire",
      slug:"fire"
    },
    {
      name:"water",
      slug:"water"
    },
    {
      name:"weather",
      slug:"weather"
    },
    {
      name:"wind",
      slug:"wind"
    }
  ],
  timelines: [],
};

@State<SignalsModel>({
  name: 'BH_APP_SIGNALS',
  defaults: _SignalsDefault,
})
@Injectable()
export class SignalsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private signalsService: SignalsService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.dispatch(new LoadSignalsFromApi());
    ctx.dispatch(new InitSSESignals());
    ctx.dispatch(new InitSSETimelines());
  }

  ngxsOnChanges(change: NgxsSimpleChange<SignalsModel>): void {}

  ngxsAfterBootstrap(ctx: StateContext<SignalsModel>) {}

  @Selector()
  static selectActualSignals(state: SignalsModel) {
    return state.actual;
  }
  @Selector()
  static selectSignalsTypes(state: SignalsModel) {
    return state.types;
  }
  @Selector()
  static selectActualTimelines(state: SignalsModel) {
    return state.timelines;
  }

  @Selector()
  static selectActualSignalsList(state: SignalsModel) {
    const _all_actual_signals = state.actual.find((actual) => actual.type === 'all');
    return _all_actual_signals.list;
  }

  static selectTypesWithActual(regions: any[], signals: any[], types: SignalTypeModel[], _region_id: string) {
    // Нахождение всех родителей для заданного _region_id
    const findAllParents = (_region_id: string): any[] => {
      let parents = [];
      let current = regions.find(region => region.sid === _region_id);

      while (current && current.pid !== undefined) {
        current = regions.find(region => region.sid === current.pid);
        if (current) parents.push(current);
      }

      return parents;
    };

    // Определение активных типов сигналов и их времени начала для региона и его родителей
    const findActiveSignalsAndStart = (parents: any[]): Map<string, string> => {
      const parentSids = parents.map(parent => parent.sid).concat(_region_id);
      const activeSignals = new Map<string, string>();

      signals.forEach(signal => {
        if (parentSids.includes(signal.sid)) {
          signal.signals.forEach(s => {
            if (!activeSignals.has(s.type) || new Date(activeSignals.get(s.type)!) > new Date(s.start)) {
              activeSignals.set(s.type, s.start);
            }
          });
        }
      });

      return activeSignals;
    };

    // Нахождение родителей
    const parents = findAllParents(_region_id);

    // Определение активных сигналов и их времени начала
    const activeSignalsAndStart = findActiveSignalsAndStart(parents);

    // Обновление списка types с учетом активных сигналов и времени их начала
    return types.map(type => ({
      ...type,
      actual: activeSignalsAndStart.has(type.slug), // Установка actual в true, если тип активен
      start: activeSignalsAndStart.get(type.slug) || null // Добавление времени начала, если тип активен
    }));
  }


  static selectActualSignalsByRegionId( _region_id: string ): any {
    return createSelector([SignalsState, AtuState.selectAtu], (state: SignalsModel, _atu: AtuItemModel[]) => {
      const allActualSignals: any = state.actual.find((_actual_signal) => _actual_signal.type == 'all').list;
      return this.selectTypesWithActual(_atu, allActualSignals, state.types, _region_id);
    });
  }

  static selectRegionBySlug( _slug: string ): any {
    return createSelector([ AtuState.selectAtu ], ( _atu: AtuItemModel[]) => {
      return _atu.find(x => x.slug === _slug);
    });
  }

  @Action(UpdateActualSignals)
  updateActualSignals(ctx: StateContext<SignalsModel>, UpdateActualSignals: { signals: SignalItemModel[]; }) {
    const state = ctx.getState();
    let _signals = UpdateActualSignals.signals;
    //console.log('Signals:', _signals);
    let _actual_signals = [
      this.detectSignalsByType('all', _signals),
      this.detectSignalsByType('air', _signals),
      this.detectSignalsByType('chemical', _signals),
      this.detectSignalsByType('radiation', _signals),
      this.detectSignalsByType('artillery', _signals),
      this.detectSignalsByType('fight', _signals),
      this.detectSignalsByType('fire', _signals),
      this.detectSignalsByType('water', _signals),
      this.detectSignalsByType('weather', _signals),
      this.detectSignalsByType('wind', _signals),
    ];
    if(JSON.stringify(state.actual) !== JSON.stringify(_actual_signals)) {
      ctx.dispatch(new LoadAtuActualGeo(_signals));
      ctx.patchState({
        ...state,
        actual: _actual_signals
      });
    }

  }

  @Action(UpdateActualTimelines)
  updateActualTimelines(ctx: StateContext<SignalsModel>, UpdateActualTimelines: { timelines: any[]; }) {
    const state = ctx.getState();
    let _timelines = UpdateActualTimelines.timelines;
    //console.log('Timeline:', _timelines);
    if(JSON.stringify(state.timelines) !== JSON.stringify(_timelines)) {
      ctx.patchState({
        ...state,
        timelines: _timelines
      });
    }

  }

  @Action(LoadSignalsFromJson)
  async loadSignalsFromJson(ctx: StateContext<SignalsModel>) {
    const _signals = await this.signalsService.getSignalsFromJson().toPromise();
    if(_signals) {
      ctx.dispatch(new UpdateActualSignals(_signals));
    }
  }

  @Action(LoadSignalsFromApi)
  async loadSignalsFromApi(ctx: StateContext<SignalsModel>) {
/*    const _signals = await this.signalsService.get().toPromise();
    if(_signals) {
      ctx.dispatch(new UpdateActualSignals(_signals));
    }*/
  }

  @Action(InitSSESignals)
  async initSSESignals(ctx: StateContext<SignalsModel>) {
/*    const _signals = this.signalsService.sseSignals();
    if (!_signals) {
      return;
    }
    _signals.onmessage = ({ data }) => {
      let _message = JSON.parse(data);
      if(_message.type) {
        this.store.dispatch(Object.assign(Object.assign({}, _message.data), { type: _message.type }));
      }
    };*/
  }

  @Action(InitSSETimelines)
  async initSSETimelines(ctx: StateContext<SignalsModel>) {
/*    const _signals = this.signalsService.sseTimelines();
    if (!_signals) {
      return;
    }
    _signals.onmessage = ({ data }) => {
      let _message = JSON.parse(data);
      if(_message.type) {
        this.store.dispatch(Object.assign(Object.assign({}, _message.data), { type: _message.type }));
      }
    };*/
  }


  detectSignalsByType(type: string, signals: SignalItemModel[]): any {
    const _ATU = this.store.selectSnapshot(AtuState.selectAtu);
    //const getPath = (sid, records) => sid === "11000000000000000" ? '' : getPath(records.find(r => r.sid === sid).pid, records) + '/' + records.find(r => r.sid === sid).slug;
    const getPath = (sid, records) => {
      // Check if the sid matches the base condition to stop recursion
      if (sid === "11000000000000000") return '';

      // Find the record for the current sid
      const record = records.find(r => r.sid === sid);

      // Check if the record is found
      if (!record) {
        // Handle the case where no record is found. You might want to throw an error or return a default value
       // console.error(`No record found for sid: ${sid}`);
        return ''; // or throw new Error(`No record found for sid: ${sid}`);
      }

      // Recursively build the path, now that we're sure record is defined
      return getPath(record.pid, records) + '/' + record.slug;
    };
    const _signals_with_slug = signals.map(item => ({
      ...item,
      slug: _ATU.find((_region) => _region.sid === item.sid)?.slug,
      path: `/${getPath(item.sid, _ATU).slice(1)}/`
    }));
    const _signals_with_all = _signals_with_slug
      .map(item => ({
        ...item,
        signals: this.selectTypesWithActual(_ATU, signals, this.store.selectSnapshot(SignalsState.selectSignalsTypes), item.sid).filter((_act) => _act.actual)
      }));

    const _signals_by_type = _signals_with_slug
      .filter((signal) => signal.signals.some(signal => signal.type === type))
      .map(item => ({
        ...item,
        signals: item.signals.filter(signal => signal.type === type)
      }));
    return {
      type: type,
      count: type == 'all'? _signals_with_slug.length:_signals_by_type.length,
      list: type == 'all'? _signals_with_all:_signals_by_type
    }
  }


  private selectTypesWithActual(regions: any[], signals: any[], types: SignalTypeModel[], _region_id: string) {
    // Нахождение всех родителей для заданного _region_id
    const findAllParents = (_region_id: string): any[] => {
      let parents = [];
      let current = regions.find(region => region.sid === _region_id);

      while (current && current.pid !== undefined) {
        current = regions.find(region => region.sid === current.pid);
        if (current) parents.push(current);
      }

      return parents;
    };

    // Определение активных типов сигналов и их времени начала для региона и его родителей
    const findActiveSignalsAndStart = (parents: any[]): Map<string, string> => {
      const parentSids = parents.map(parent => parent.sid).concat(_region_id);
      const activeSignals = new Map<string, string>();

      signals.forEach(signal => {
        if (parentSids.includes(signal.sid)) {
          signal.signals.forEach(s => {
            if (!activeSignals.has(s.type) || new Date(activeSignals.get(s.type)!) > new Date(s.start)) {
              activeSignals.set(s.type, s.start);
            }
          });
        }
      });

      return activeSignals;
    };

    // Нахождение родителей
    const parents = findAllParents(_region_id);


    // Определение активных сигналов и их времени начала
    const activeSignalsAndStart = findActiveSignalsAndStart(parents);

    // Обновление списка types с учетом активных сигналов и времени их начала
    return types.map(type => ({
      type: type.slug,
      actual: activeSignalsAndStart.has(type.slug), // Установка actual в true, если тип активен
      start: activeSignalsAndStart.get(type.slug) || null, // Добавление времени начала, если тип активен
      end:null
    }));
  }
}
