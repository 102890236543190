import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {SignalItemModel} from "../_models/signals.model";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class SignalsService {

  isBrowser: boolean;

  constructor(private http: HttpClient,  @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public getSignalsFromJson(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<SignalItemModel[]>(API.url('signals'));
  }


  public get(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<SignalItemModel[]>(API.url('signals'+randomHash));
  }

  public sseSignals(){
    if(this.isBrowser) {
      const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
      return new EventSource(API.url('signals/actual'+randomHash));
    } else {
      return undefined;
    }

  }
  public sseTimelines(){
    if(this.isBrowser) {
      const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
      return new EventSource(API.url('signals/timelines'+randomHash));
    } else {
      return undefined;
    }

  }
}
